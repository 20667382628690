import React from 'react';
import { List, Item } from './styles';

const Pager = props => {
	//投稿数の数だけ連番の入ったリストを作成
	//(ループだと見通しが悪くなるため)
	const lenArr = [...Array(props.pageLen)].map((num,idx) => idx + 1);
		return (
			<List>
				{lenArr.map( i => (
						<Item
							children={i}
							isCur={ i == props.cur ? true : false }
							onClick={ () => props.updateFunc(i) }
							key={ i }
						/>
				))}
			</List>
		)
}

export default Pager;
