import React from 'react';
import styled from 'styled-components';
import ContactIntroBase from '@components/en/common/contact-intro';
import NewsListBase from '@components/common/news-list';
import { size } from '@src/settings';

export const NewsList = styled(NewsListBase)`
	margin-bottom: 0;
	margin-top: ${ size.pageFirstGap*0.1 }rem;
	margin-bottom: ${ size.pageLastGap*0.1 }rem;
`;

export const ContactIntro = styled( ContactIntroBase )`
	${ NewsList } + &{
		margin-top: ${ size.pageLastGap*0.1 }rem;
	}
`

