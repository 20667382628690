import React from 'react';

export const News = props => (
	<svg viewBox="0 0 143 34" { ...props }>
		<path d="M1.2,1.1h6.4l14.8,19.4V1.1h6.8v31.5h-5.9L8,12.6v20H1.2V1.1z"/>
		<path d="M36.7,1.1h23.8v6.2H43.6v6.4h14.8v6.2H43.6v6.6h17.1v6.2h-24C36.7,32.7,36.7,1.1,36.7,1.1z"/>
		<path d="M64.5,1.1h7.4l6.5,21.3L85.5,1h5.9l7.1,21.4L105,1.1h7.2l-10.8,31.7h-6l-7.1-20.6l-7.1,20.6h-6L64.5,1.1z"/>
		<path d="M114.6,28l4.1-4.9c2.8,2.3,5.8,3.8,9.4,3.8c2.8,0,4.5-1.1,4.5-3v-0.1c0-1.8-1.1-2.7-6.3-4
			c-6.3-1.6-10.4-3.4-10.4-9.6v-0.1c0-5.7,4.6-9.5,11-9.5c4.6,0,8.5,1.4,11.7,4L135,9.9c-2.8-1.9-5.5-3.1-8.2-3.1s-4,1.2-4,2.7v0.1
			c0,2.1,1.4,2.7,6.8,4.1c6.4,1.7,10,4,10,9.5v0.1c0,6.3-4.8,9.8-11.6,9.8C123.2,33,118.4,31.4,114.6,28z"/>
	</svg>
)
