import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from "styled-media-query";

import Btn from '@components/common/btn';
import { breakpoint, color } from "@src/settings";
import { Contact }  from '@components/common/contact-intro/svgs';
import { Cont, Frame, Title, Txts } from '@components/common/contact-intro/styles';

const ContactIntro = (props) => {
	return (
		<Cont className={ props.className }>
			<Frame>
				<Title>
					<h2><Contact /></h2>
				</Title>
				<Txts>
					<h3>Please feel free to contact us for anything.</h3>
					<p>For inquiries, please contact us using the email form below. <br />The staff will respond promptly.</p>
					<Btn className="btn" to="/en/contact" />
				</Txts>
			</Frame>
		</Cont>
	);
}

export default ContactIntro;

