import React from 'react';
import styled from 'styled-components';
import { color, size, mixin } from '@src/settings';

export const List = styled.ul`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	${ mixin.baseWidthSet }
	margin-top: 4rem;
	margin-bottom: ${ size.pageLastGap*0.1}rem;
`;

export const Item = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.6rem;
	height: 3.6rem;
	background-color: ${ props => props.isCur ? color.yellow : color.blue };
	color: #FFF;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1;
	cursor: pointer;
	margin: 1rem;
`
